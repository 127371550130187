import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  BlockDes,
  Row,
  Button,
} from "../../../components/Component";
import Image from "../../../components/image/Image";
import CheckboxRadio from "../../../components/input/checkbox-radio/checkbox-radio";
import Input from "../../../components/input/input/input";
import Select from "../../../components/input/select/select";
import useForm from "../../../hooks/use-form";
import useRequest from "../../../hooks/use-request";
import apiRoutes from "../../../routes/api-routes";
import { addAlert } from "../../../utils/alert";
const CreatUpdateModal = ({
  show,
  onClose,
  category,
  onSuccess,
  parentId,
  websitesOption,
}) => {
  const { isLoading, sendFormRequest, validationErros, resetValidationErrors } =
    useRequest();

  const { inputs, setOne, setFields, reset } = useForm({
    name: "",
    is_featured: "",
    image: "",
    website: "",
  });

  useEffect(() => {
    if (category) {
      setFields({
        name: category.name ?? "",
        is_featured: category.is_featured ? 1 : 0,
        image: "",
        website: category.website ?? "",
      });
    }
  }, [category, setFields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      const data = new FormData();
      data.append("name", inputs.name);
      data.append("website", inputs.website);
      data.append("is_featured", inputs.is_featured);
      data.append("image", inputs.image?.file ?? "");

      data.append("parent_id", parentId ?? "");

      if (category) {
        data.append("_method", "PATCH");
      }

      let response = await sendFormRequest({
        url: category
          ? apiRoutes.CATEGORY_UPDATE(category.id)
          : apiRoutes.CATEGORY_CREATE,
        method: category ? "PATCH" : "POST",
        body: data,
      });

      if (response) {
        reset();
        addAlert(
          toast.TYPE.SUCCESS,
          `Category has been ${category ? "updated" : "added"} successfully`
        );
        onClose();
        onSuccess();
      }
    }
  };
  return (
    <>
      <SimpleBar
        className={`nk-add-product toggle-slide toggle-slide-right toggle-screen-any ${
          show ? "content-active" : ""
        }`}
      >
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h5">
              {category ? "Edit" : "Add"} {parentId && " Sub "} Category
            </BlockTitle>
            <BlockDes>
              <p>
                Add information and {category ? "create" : "update"} category
              </p>
            </BlockDes>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <form onSubmit={onSubmit}>
            <Row className="g-3">
              {category?.image?.access_path && (
                <Col size={{ sm: 12 }}>
                  <Image
                    src={category.image?.access_path}
                    alt="product"
                    className="thumb"
                    style={{
                      maxHeight: "150px",
                      maxWidth: "90%",
                      display: "block",
                      margin: "auto",
                    }}
                  />
                </Col>
              )}
              {!parentId && (
                <Input
                  columnConfig={{ sm: 12 }}
                  label={category ? "new image" : "Image"}
                  set={setOne}
                  type="file"
                  name="image"
                  model={inputs.image}
                  error={validationErros?.image}
                />
              )}
              <Input
                columnConfig={{ sm: 12 }}
                label="name"
                set={setOne}
                name="name"
                model={inputs.name}
                error={validationErros?.name}
              />

              {!parentId && (
                <>
                  <Select
                    options={websitesOption}
                    columnConfig={{ sm: 12 }}
                    label="Website"
                    set={setOne}
                    name="website"
                    model={inputs.website}
                    error={validationErros?.website}
                  />
                  <CheckboxRadio
                    options={[
                      {
                        label: "Yes",
                        value: 1,
                      },
                      {
                        label: "No",
                        value: 0,
                      },
                    ]}
                    columnConfig={{ sm: 12 }}
                    label="Is Featured"
                    type={"radio"}
                    set={setOne}
                    name="is_featured"
                    model={inputs.is_featured}
                    error={validationErros?.is_featured}
                  />
                </>
              )}

              <Col size={{ sm: 12 }}>
                <Button isLoading={isLoading}>
                  {category ? "Update" : "Create"}
                </Button>
              </Col>
            </Row>
          </form>
        </Block>
      </SimpleBar>
      {show && (
        <div
          className="toggle-overlay"
          onClick={() => {
            reset();
            resetValidationErrors();
            onClose();
          }}
        ></div>
      )}
    </>
  );
};

export default CreatUpdateModal;
