import { useEffect, useState } from "react";

import { Form, Row, Col } from "reactstrap";
import {
  BlockBetween,
  BlockHeadContent,
  Button,
  Icon,
} from "../../../components/Component";
import useForm from "../../../hooks/use-form";
import useRequest from "../../../hooks/use-request";
import apiRoutes from "../../../routes/api-routes";
import { getVariants } from "../../../utils/methods";
import { ProductOptionTypes, Websites } from "../../../utils/statics";
import OptionCard from "./option-card";

const Options = ({
  isOptionUpdated,
  setIsOptionUpdated,
  stepState: { hasNext, next, hasPrev, prev, ...stepState },
  onUnmount,
  fields,
  ...props
}) => {
  const { isLoading, validationErros, sendRequest, sendFormRequest } =
    useRequest();
  const { inputs, setOne, setFields } = useForm({});

  const addOption = () => {
    setOne("options", [
      ...inputs.options,
      {
        id: Math.random(),
        name: "",
        type: ProductOptionTypes.TEXT,
        values: [
          {
            id: Math.random(),
            value: "",
            color: "#000000",
          },
        ],
      },
    ]);
    setIsOptionUpdated(true);
  };

  const getVaraints = () => {
    let variants = [];
    let needNew = isOptionUpdated || inputs.variants?.length == 0;
    if (inputs.options?.length > 0) {
      let arrays = [];
      inputs.options?.forEach((attr, index) => {
        arrays.push(attr.values.map((v) => [v.value, v.color]));
      });

      variants = getVariants(...arrays)?.map((optionValues, index) => ({
        option_values: optionValues,
        stock: needNew ? "" : inputs.variants[index].stock,
        current_price: needNew ? "" : inputs.variants[index].current_price,
        standard_price: needNew ? "" : inputs.variants[index].standard_price,
        unit_weight: needNew ? "" : inputs.variants[index].unit_weight,
        image_index: needNew ? null : inputs.variants[index].image_index,
        id: needNew ? "" : inputs.variants[index].id,
        is_existing: needNew
          ? false
          : inputs.variants[index].is_existing ?? false,
      }));
    } else {
      variants = [
        {
          stock: needNew ? "" : inputs.variants[0].stock,
          current_price: needNew ? "" : inputs.variants[0].current_price,
          standard_price: needNew ? "" : inputs.variants[0].standard_price,
          unit_weight: needNew ? "" : inputs.variants[0].unit_weight,
          image_index: needNew ? null : inputs.variants[0].image_index,
          id: needNew ? "" : inputs.variants[0].id,
          is_existing: needNew
            ? false
            : inputs.variants[0].is_existing ?? false,
        },
      ];
    }
    return variants;
  };

  const getCustomVariants = () => {
    let custom_variants = [];
    if (
      inputs.website === Websites.SCKALI_DESGIN
    ) {
      let needNew = isOptionUpdated || inputs.custom_variants?.length == 0;

      //Special product option value to create custom measure have combintion
      let arrays = [[[]]];

      inputs.options
        ?.filter((opt) => opt.type == ProductOptionTypes.COLOR)
        ?.forEach((attr, index) => {
          arrays.push(attr.values.map((v) => [v.value, v.color]));
        });

      custom_variants = getVariants(...arrays)?.map((optionValues, index) => {
        return {
          option_values: optionValues,
          current_price: needNew
            ? ""
            : inputs.custom_variants[index].current_price,
          standard_price: needNew
            ? ""
            : inputs.custom_variants[index].standard_price,
          unit_weight: needNew ? "" : inputs.custom_variants[index].unit_weight,
          image_index: needNew ? "" : inputs.custom_variants[index].image_index,
          id: needNew ? "" : inputs.custom_variants[index].id,
          is_existing: needNew
            ? false
            : inputs.custom_variants[index].is_existing ?? false,
        };
      });
    }

    return custom_variants;
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (!isLoading) {
      let response = await sendRequest({
        url: apiRoutes.PRODUCT_VALIDATE_OPTIONS,
        method: "POST",
        body: {
          options: inputs.options,
        },
      });
      if (response) {
        onUnmount({
          ...inputs,
          variants: getVaraints(),
          custom_variants: getCustomVariants(),
        });
        hasNext && next();
      }
    }
  };

  useEffect(() => {
    setFields(fields);

    // if (fields.options?.length === 0) {
    //   setIsOptionUpdated(true);
    // }
  }, [fields, setFields, setIsOptionUpdated]);

  return (
    <div className="">
      <Form onSubmit={onFormSubmit}>
        <Row className="g-4">
          {inputs?.options?.map((option, index) => (
            <OptionCard
              validationErros={validationErros}
              setFields={setFields}
              index={index}
              inputs={inputs}
              setOne={setOne}
              key={index}
              setIsOptionUpdated={setIsOptionUpdated}
            />
          ))}

          <Col sm={12} className=" mt-3">
            <Button
              onClick={addOption}
              size="lg"
              className={"m-auto d-block d-flex align-items-center "}
              type="button"
              color="outline-primary"
            >
              <Icon name={"plus"} />
              <span>Add Option</span>
            </Button>
          </Col>

          <Col sm={12} className=" mt-3">
            <BlockBetween>
              <BlockHeadContent>
                {hasPrev && (
                  <Button
                    onClick={() => {
                      onUnmount();
                      prev();
                    }}
                    isLoading={isLoading}
                    size="lg"
                    className={"m-auto d-block btn-dim"}
                    type="button"
                    color="light"
                  >
                    Previous
                  </Button>
                )}
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  isLoading={isLoading}
                  size="lg"
                  className={"m-auto d-block"}
                  type="submit"
                  color="primary"
                >
                  Next
                </Button>
              </BlockHeadContent>
            </BlockBetween>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Options;
