// const resourceRoute = (name) => {
//   return {
//     [name + "_CREATE"]: "/admin/admins",
//     [name + "_INDEX"]: "/admin/admins",
//     [name + "_SHOW"]: (id) => `/admin/admins/${id}`,
//     [name + "_UPDATE"]: (id) => `/admin/admins/${id}`,
//     [name + "_DELETE"]: (id) => `/admin/admins/${id}`,
//   };
// };

const apiRoutes = {
  ADMIN_LOGIN: "/admin/account/login",
  ADMIN_REGISTER: "/admin/account/register",
  ADMIN_LOGOUT: "/admin/account/logout",
  ADMIN_INITIALIZE: "/admin/initialize",
  ADMIN_LOGIN_ACTIVITIES: "/admin/account/activities",
  ADMIN_REFRESH_TOKEN: "/admin/account/refresh-token",
  ADMIN_PROFILE_UPDATE: "/admin/account",
  ADMIN_PROFILE_PASSWORD_UPDATE: "/admin/account/password",
  ADMIN_REMOVE_TOKEN: (id) => `/admin/account/tokens/${id}`,

  OTP_SEND: "/admin/account/otp/send",
  LOGIN_OTP_VERIFY: "/admin/account/otp/verify",
  LOGIN_OTP_RESEND: "/admin/account/otp/resend",

  TWO_FACTOR_ENABLE: "/admin/account/two-factor/enable",
  TWO_FACTOR_DISABLE: "/admin/account/two-factor/disable",

  ADMIN_COUNT: "/admin/admins/count",
  ADMIN_OPTIONS: "/admin/admins/options",
  ADMIN_CREATE: "/admin/admins",
  ADMIN_INDEX: "/admin/admins",
  ADMIN_SHOW: (id) => `/admin/admins/${id}`,
  ADMIN_UPDATE: (id) => `/admin/admins/${id}`,
  ADMIN_DELETE: (id) => `/admin/admins/${id}`,

  CARRIER_CREATE: "/admin/carriers",
  CARRIER_INDEX: "/admin/carriers",
  CARRIER_SHOW: (id) => `/admin/carriers/${id}`,
  CARRIER_UPDATE: (id) => `/admin/carriers/${id}`,
  CARRIER_DELETE: (id) => `/admin/carriers/${id}`,

  CARRIER_COUNTRY_CREATE: (carrierId) =>
    `/admin/carriers/${carrierId}/countries`,
  CARRIER_COUNTRY_INDEX: (carrierId) =>
    `/admin/carriers/${carrierId}/countries`,
  CARRIER_COUNTRY_SHOW: (carrierId, countryId) =>
    `/admin/carriers/${carrierId}/countries/${countryId}`,
  CARRIER_COUNTRY_UPDATE: (carrierId, countryId) =>
    `/admin/carriers/${carrierId}/countries/${countryId}`,
  CARRIER_COUNTRY_DELETE: (carrierId, countryId) =>
    `/admin/carriers/${carrierId}/countries/${countryId}`,

  CATEGORY_CREATE: "/admin/categories",
  CATEGORY_INDEX: "/admin/categories",
  CATEGORY_SHOW: (id) => `/admin/categories/${id}`,
  CATEGORY_UPDATE: (id) => `/admin/categories/${id}`,
  CATEGORY_DELETE: (id) => `/admin/categories/${id}`,
  CATEGORY_UPDATE_FEATURED: (id) => `/admin/categories/${id}/is_featured`,

  SIZE_GUIDE_CREATE: "/admin/size-guides",
  SIZE_GUIDE_INDEX: "/admin/size-guides",
  SIZE_GUIDE_SHOW: (id) => `/admin/size-guides/${id}`,
  SIZE_GUIDE_UPDATE: (id) => `/admin/size-guides/${id}`,
  SIZE_GUIDE_DELETE: (id) => `/admin/size-guides/${id}`,

  BANNER_CREATE: (type) => `/admin/${type}/banners`,
  BANNER_INDEX: (type) => `/admin/${type}/banners`,
  BANNER_SHOW: (type, id) => `/admin/${type}/banners/${id}`,
  BANNER_UPDATE: (type, id) => `/admin/${type}/banners/${id}`,
  BANNER_DELETE: (type, id) => `/admin/${type}/banners/${id}`,

  COUNTRIES_INDEX: "/countries",

  PRODUCT_COUNT: "/admin/products/count",
  PRODUCT_OPTIONS: "/admin/products/options",
  PRODUCT_CREATE: "/admin/products",
  PRODUCT_INDEX: "/admin/products",
  PRODUCT_UPDATE_FEATURED: (id) => `/admin/products/${id}/is_featured`,
  PRODUCT_SHOW: (id) => `/admin/products/${id}`,
  PRODUCT_UPDATE: (id) => `/admin/products/${id}`,
  PRODUCT_DELETE: (id) => `/admin/products/${id}`,
  PRODUCT_VALIDATE_DETAILS: "/admin/products/validate/details",
  PRODUCT_VALIDATE_OPTIONS: "/admin/products/validate/options",

  ORDER_INDEX: "/admin/orders",
  ORDER_SHOW: (orderId) => "/admin/orders/" + orderId,
  ORDER_REFUND: (orderProductId) =>
    "/admin/orders/" + orderProductId + "/refund",
  ORDER_UPDATE_STATUS: (orderProductId) => "/admin/orders/" + orderProductId,

  ERROR_STORE: "/admin/errors",

  EVENT_CREATE: "/admin/events",
  EVENT_INDEX: "/admin/events",
  EVENT_SHOW: (id) => `/admin/events/${id}`,
  EVENT_UPDATE: (id) => `/admin/events/${id}`,
  EVENT_DELETE: (id) => `/admin/events/${id}`,
  EVENT_UPDATE_STATUS: (id) => "/admin/events/" + id + "/registration-status",

  EVENT_REGISTRATION_INDEX: (eventId) =>
    `/admin/events/${eventId}/event-registrations`,

  CUSTOMER_INDEX: "/admin/customers",
  CUSTOMER_UPDATE: (customerId) => "/admin/customers/" + customerId,
  CUSTOMER_SHOW: (customerId) => "/admin/customers/" + customerId,

  DIRECT_MESSAGE_INDEX: "/admin/direct-messages",
  DIRECT_MESSAGE_UPDATE: (msgId) => "/admin/direct-messages/" + msgId,
  DIRECT_MESSAGE_SHOW: (msgId) => "/admin/direct-messages/" + msgId,

  NEWSLETTER_SUBSCRIBERS_INDEX: "/admin/newsletter-subscribers",
  NEWSLETTER_SUBSCRIBERS_DELETE: (newsletterSubscriberId) =>
    "/admin/newsletter-subscribers/" + newsletterSubscriberId,

  NOTIFICATION_CONFIGURATION_CREATE: "/admin/notification-configurations",
  NOTIFICATION_CONFIGURATION_INDEX: "/admin/notification-configurations",
  NOTIFICATION_CONFIGURATION_SHOW: (id) =>
    `/admin/notification-configurations/${id}`,
  NOTIFICATION_CONFIGURATION_UPDATE: (id) =>
    `/admin/notification-configurations/${id}`,
  NOTIFICATION_CONFIGURATION_DELETE: (id) =>
    `/admin/notification-configurations/${id}`,
};

export default apiRoutes;
